@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap");
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: rgb(167, 186, 81);
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

header {
  border-bottom: 1px solid rgb(17, 17, 17);
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Amatic SC", cursive;
  font-size: 1.4rem;
  text-align: center;
}

main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin: 10px 0 10px 0;
  flex-grow: 1;
}

main iframe, main .offline-thumbnail {
  width: 80vw;
  aspect-ratio: 16/9;
}

main .note {
  width: 75vw;
  font-family:Georgia, 'Times New Roman', Times, serif;
  word-spacing: .1em;
  text-align: justify;
  font-size: 1.2rem;
}


main .feed-btn{
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

main .feed-btn:hover,
main .feed-btn:active {
  background-color: #fc3932;
}

main .feed-btn:active {
  opacity: .5;
}

main .feed-btn.invisible {
  display: none;
}


main .disabled, .disabled.feed-btn:hover {
  cursor: default;
  background-color: gray;
  border: 1px solid gray;
  color: rgb(215, 213, 213);
}
main #myBar {
  height: 12px;
  width: 0%;
  background-color: #427bff;
  border-radius: 8px;
  align-self: flex-start;
}

footer {
  height: 20px;
  background-color: rgb(15, 14, 14);
  color: rgb(236, 236, 236);
  width: 100vw;
  text-align: center;
  padding: 8px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  margin-top: 40px;
  
}

footer a {
  color: inherit;
}

